<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    let date = new Date()

    if (this.$route.name === 'commissions') {
      this.$router.push({
        name: 'commission-statement.entry',
        params: {
          month: ('0' + (date.getMonth() + 1)).slice(-2),
          year: date.getFullYear(),
        },
      })
    }
  },
}
</script>

<style></style>
